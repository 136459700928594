import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PostLayout.tsx";
import BlogHeading from '../../components/BlogHeading';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BlogHeading heading={props.pageContext.frontmatter.title} secondHeading={props.pageContext.frontmatter.description} mdxType="BlogHeading" />
    <p>{`Today is Friday. Here's my recap of the past few days. I've created two folders titled Portraits and Analogue Attic, where the first represents pieces inspired by Seb Wildblood's music style. The latter resembles the music of the Australian Label Analogue Attic.`}</p>
    <h2>{`Session View`}</h2>
    <p>{`To begin each project, I've decided to utilize the session view feature found in Ableton. My initial intention is to use these views as a store of place for cool instruments, which can be used throughout the project. When the project is complete, this file can be used as the live version to play out these songs. The clips very essential to be stored as the purpose of this parent-like file is to only store instruments.`}</p>
    <h2>{`Making of my first track...`}</h2>
    <p>{`I did somewhat struggle in making my first track. I went for a walk this afternoon, listening to what I have made. I intended the track to be likened to that of Seb Wildbloods SoundCloud Mix (I know the one). I imagine cruising around Melbourne city on a warm Autumn night.`}</p>
    <h2></h2>
    <h2>{`Where from here...`}</h2>
    <p>{`So I have two more days to finish this track. My plan is to restart the track from scratch and focus on elements I enjoy from the Seb Wildblood mix. For now, I leave with this nice quote from the great Bill Evans.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"I believe in things that are developed through hard work. I always like people who have developed long and hard, especially through introspection and a lot of dedication. I think what they arrive at is usually a much deeper and more beautiful thing than the person who seems to have that ability and fluidity from the beginning. I say this because it's a good message to give to young talents who feel as I used to."`}</p>
      <br />
      <br />
Bill Evans
    </blockquote>
    <p>{`Making of my first track...`}</p>
    <p>{`There was a cool Youtube post by a developer called Scott Moss. Scott stresses that to build foundational knowledge, one must put in extra time and effort during the initial stages of learning development. I'm applying this idea to my music production. I've noticed that with long breaks between each session, I am relearning old content rather than moving forward. Let's see how much I can do in the new two weeks before work starts.
Where from here...
So I have two more days to finish this track. I plan to restart the way from scratch and focus on elements I enjoy from the Seb Wildblood mix. For now, I leave with this nice quote from the great Bill Evans.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      